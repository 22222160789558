import React, { useState, useRef, useEffect } from 'react'
import QrReader from 'react-qr-reader'
import Countdown from 'react-countdown'
import moment from 'moment'

import {getParticipant} from './services/users'
import {getEvent, enterWaitList} from './services/event'

import './App.css'

const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const showLoading = (value) => {
  const $ = window.$
  if(value) $("#page-loader").removeClass("hide-this")
  else $("#page-loader").addClass("hide-this")
}

const countdownStyle = {
  fontWeight: 'bold',
  width: '42px',
  padding: '8px',
  background: 'dimgray',
  borderRadius: '5px',
  marginLeft: '15px',
  marginRight: '5px',
}


function App() {

  const [scanning, setScanning] = useState(false)
  const [error, setError] = useState(undefined)
  const [event, setEvent] = useState(undefined)
  const [participant, setParticipant] = useState(undefined)
  const [scanMethod, setScanMethod] = useState(undefined)

  const [fullName, setFullName] = useState(undefined)
  const [email, setEmail] = useState(undefined)
  const [phone, setPhone] = useState(undefined)
  const [url1, setUrl1] = useState(undefined)
  const [notes, setNotes] = useState(undefined)
  const [requestSubmitted, setRequestSubmitted] = useState(undefined)

  const reader = useRef(null)

  const handleScan = str => {
    console.log(str)
    if (str) {
      const isValid = regexExp.test(str)
      
      if(isValid) {

        getParticipant(str)
          .then(participant => {
            showLoading(true)
            setParticipant(participant)
            showLoading(false)  
          })
          .catch(e => {
            getEvent(str)
              .then(eventData => {
                showLoading(true)
                setEvent(eventData)
                showLoading(false) 
              })
              .catch(e2 => {
                console.error(e)
                setError('The code you presented is invalid.')
              })
          })
      }
      else {
      	console.log('Code does not match regex criteria.')
        setError('The code you presented is invalid.')
      }
    }
    else if(scanMethod !== 'camera') {
      console.log('Could not extract code from image.')
      setError('The image you provided is invalid.')
    }
  }

  const submitRequest = () => {
    enterWaitList(event.id, fullName, email, phone, url1, notes)
      .then(response => {
        console.log('Success!!')
        setRequestSubmitted(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleImage = e => {
    console.log(e)
  }

  const handleError = err => {
    console.error(err)
    setError(err)
  }


  useEffect(() => {
    setError(undefined)
  }, [scanMethod])


  if(requestSubmitted) {
    return (
      <div id="contact-form">

        <h1>{event.name.toUpperCase()}</h1>

        {
          event.image &&
          <div className="center">
            <img src={event.image} style={{maxWidth: '20rem', marginBottom: '2rem', marginTop: '2rem'}} />
          </div>
        }

        <p className="center">
          Thanks for submitting your participation request.<br />
          You will be contacted only in case your request is approved.
        </p>

      </div>
    )
  }

  if(event) {
    return (
      <div id="contact-form">

        <h4>WAITLIST INFORMATION</h4>

        <div className="control-group">
          <label className="nameLabel">Full Name</label>
          <input type="text" className="form-control" onChange={e => setFullName(e.target.value)} />
        </div>

        <div className="control-group">
          <label className="nameLabel">Email</label>
          <input type="email" className="form-control" onChange={e => setEmail(e.target.value)} />
        </div>

        <div className="control-group">
          <label className="nameLabel">Phone Number</label>
          <input type="tel" placeholder="888-888-8888" pattern="[0-9]{3}\-[0-9]{3}\-[0-9]{4}" maxlength="12" className="form-control"  onChange={e => setPhone(e.target.value)} />
        </div>

        <div className="control-group">
          <label className="nameLabel">Website / Portfolio</label>
          <input type="url" className="form-control"  onChange={e => setUrl1(e.target.value)} />
        </div>

        <div className="control-group">
          <label className="nameLabel">Notes</label>
          <textarea rows="3" class="form-control" onChange={e => setNotes(e.target.value)}></textarea>
        </div>

        <button className="form-control" onClick={submitRequest}>
          <i className="ti-arrow-right first"></i>
          <i className="ti-arrow-right second"></i>
        </button>

      </div>
    )
  }


  if(participant) {

    const goToEvent = <>
      <p className="center">Press the button to continue.</p>

      <div className="control-group">
        <a href={`/event/?p=${participant.id}`}>
          <button className="form-control">
            <i className="ti-arrow-right first"></i>
            <i className="ti-arrow-right second"></i>
          </button>
        </a>
      </div>
    </>;

    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return goToEvent;
      }

      return <div>
        <p className="center">You will be able to join the event in:</p>
        <div>
            <span style={countdownStyle}>{days}</span> <small>days</small>
            <span style={countdownStyle}>{hours}</span> <small>hours</small>
            <span style={countdownStyle}>{minutes}</span> <small>minutes</small>
            <span style={countdownStyle}>{seconds}</span> <small>seconds</small>
        </div>
        {participant.event.external_url && <p className="center" style={{marginTop: '2rem'}}><small>While you wait, you can consult <a href={participant.event.external_url}>this page</a> for further instructions and potential updates.</small></p>}
      </div>
    };

    return (
      <div id="contact-form">

        <h1>WELCOME {participant.first_name.toUpperCase()}</h1>

        <p className="center">You have been invited to:</p>

        <h4>{participant.event.name}</h4>

        {participant.event.image && 
        	<div className="center">
        		<img src={participant.event.image} style={{maxWidth: '20rem', marginBottom: '2rem', marginTop: '2rem'}} />
        	</div>
        }

        {participant.event.start_time && participant.role !== 'early_access' ?
          <Countdown date={moment(participant.event.start_time).toDate()} renderer={countdownRenderer} />
          :
          goToEvent
        }

      </div>
    )
  }


  return (
    <div id="contact-form">
      <h1>YOU NEED A CODE TO ENTER</h1>

      {!scanning && <p className="center">This is where it all starts.</p>}

      {error && <p className="center">{error}</p>}

      <center>

        {scanning ?

          <div>
            <QrReader
              ref={reader}
              delay={300}
              onError={handleError}
              onScan={handleScan}
              onImageLoad={handleImage}
              style={{ width: '100%', maxWidth: '500px', paddingBottom: '2rem', display: scanMethod !== 'camera' ? 'none' : undefined }}
              legacyMode={scanMethod !== 'camera'}
            />


            {
              scanMethod === undefined &&
              <p className="center">
                <a onClick={() => setScanMethod('camera')}>Use your camera</a><br />
                or<br />
                <a onClick={() => reader.current.openImageDialog()}>select an image</a>.
              </p>
            }
            {
              scanMethod === 'camera' &&
              <span>
                or <a onClick={() => setScanMethod(undefined)}>try another method</a>.
              </span>
            }
            
          </div>
          :
          <div className="control-group">

            <button type="submit" className="form-control" onClick={() => setScanning(true)}>

              <i className="ti-arrow-right first"></i>
              <i className="ti-arrow-right second"></i>

            </button>
          </div>

        }

      </center>
    </div>
  )
}

export default App
