import axios from 'axios'

const HOST = '/api/'

export const httpGET = (path) => {
  return axios.get(`${HOST}${path}`).then(response => {
    return response.data
  })
}


export const httpPOST = (path, data) => {
  return axios.post(`${HOST}${path}`, data).then(response => {
    return response.data
  })
}