import {httpGET, httpPOST} from '../utils'

export const getEvent = (eventId) => {
  return httpGET(`events/${eventId}`)
}

export const enterWaitList = (eventId, fullName, email, phone, url1, notes) => {
  return httpPOST(`events/${eventId}/wait_list`, {
  	full_name: fullName,
  	email,
  	phone,
  	url_1: url1,
  	notes,
  })
}

export const nonExistingEvent = () => {
  window.location.replace('https://thesecret.company/401.html')
}